$(function(){
	//cookie判定
	if ($.cookie('load_anim') != 'true') {
		$("#load_short").css("display","none");
		$.cookie('load_anim', 'true', {
			expires: 365,
			path:'/'
		});
		loadingAnimation();
	}else{
		//文字アニメーションデバッグ用。クッキー消さなくても毎回見れるように。
		//デバッグ時は13,14行目をコメントイン、16,17行目をコメントアウト
		// $("#load_short").css("display","none");
		// loadingAnimation();

		$("#load").css("display","none");
		loadingAnimation_short();
	}
});

window.onload = function(){
	if(!_cmn){
		_cmn = CmnManager.create();
		_cmn.set();
		_cmn.start();
    }
}

//1回目の訪問時のみ・文字アニメーション
function loadingAnimation(){
	var video = document.getElementById("load_mp4");
	// console.log(video);
	$("#load").find("video").animate({ opacity: 1 }, { duration: 500, easing: 'linear' });
	video.addEventListener("ended", function() {
		//可能であれば、CSSアニメーションでやったほうが軽くなる。
		$("#load").animate({ opacity: 0 }, 500, 'linear', function() {
			$("#load").addClass("no");
			// console.log("更新したよー");
		});
		// $("#load").delay(500).queue(function(){
			
		// });
	});
}

//2回目以降はラインアニメーション
function loadingAnimation_short(){
	$(window).on("load", function () {
		$("#load_short").addClass("hide_anim");
		$("#load_short").on('animationend',function(){
			$("#load_short").addClass("hide");
		});
	});
}