/**
 * Manager
 * @class Page Manager
 * @returns
 */
var Manager = Klass.create();

Manager.extend(JqManager);

//definition start
Manager.extend({
/**
 * init
 */
init:function(){
	this.timer = null;
	this.perf_no = 1;
	this.app_no = 1;
	this.anim = false;
	this.$document = $(document);
	this.$window = $(window);
},
/**
 * 設定
 */
set:function(){
	this.setParam();
	this.setObject();
},
/**
 * 開始
 */
start:function(){
	console.log("start");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this, this.setState, [],"");
	oCom.call(this, this.setEvent, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * 開始後
 */
after:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	//oCom.call(this, this.animAll, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータセット
 */
setParam:function(){
	var oSelf = this;

	// IEの設定を生かす場合コメントアウトをとる
	// if(_cmn["ua"] === "ie" && ~~_cmn["ver"] === 8){
	// 	this.ie8 = true;
	// }
	// if(_cmn["ua"] !== "ie" || (_cmn["ua"] === "ie" && ~~_cmn["ver"] >= 10)){
	// 	this.modern = true;
	// }
},

/**
 * オブジェクト設定
 */
setObject:function(){
	this.setParallax();

	var swipeOption = {
		loop: true,
		effect: 'fade',
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		speed: 2000,
	}
	var mySlide = new Swiper('.swiper-container', swipeOption);

	mySlide.on('slideChange', function () {
		// console.log('現在のスライド番号： ' + (mySlide.realIndex + 1));
		if((mySlide.realIndex + 1) == 1){
			$(".fv_li:nth-child(1)").addClass("active");
			$(".fv_li").not(".fv_li:nth-child(1)").removeClass("active");
		}
		if((mySlide.realIndex + 1) == 2){
			$(".fv_li:nth-child(2)").addClass("active");
			$(".fv_li").not(".fv_li:nth-child(2)").removeClass("active");
		}
		if((mySlide.realIndex + 1) == 3){
			$(".fv_li:nth-child(3)").addClass("active");
			$(".fv_li").not(".fv_li:nth-child(3)").removeClass("active");
		}
	});

	$(".fv_ul").find(".fv_li:nth-child(1)").on("click",function(){
		mySlide.slideTo(1);
	});
	$(".fv_ul").find(".fv_li:nth-child(2)").on("click",function(){
		mySlide.slideTo(2);
	});
	$(".fv_ul").find(".fv_li:nth-child(3)").on("click",function(){
		mySlide.slideTo(3);
	});
},
/**
 * 初期状態セット
 */
setState:function(){
	console.log("setState");
	if(!_manager.ie8){
		if(_manager.modern){
			//modern
		}else{
			//ie9
		}
	}else{
		//ie8
	}
	this.resize(null,false);
},
/**
 * setEvent
 */
setEvent:function(){
	var oSelf = this;

	//cmn
	this.setWayPoint();
	this.setResize();

	//aタグ#指定位置にスクロール移動
	_cmn.setScroll();


},
/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	var height = $(window).height();
	var iOffset = height * 0.5;
	if(_cmn.spview){
	}

	$(".top_info").waypoint(function(direction){
		if (direction === 'down') {
			$(".header_inner").addClass("open");
		}else{
			$(".header_inner").removeClass("open");
		}
	},{offset:0});

	$(".strength").waypoint(function(direction){
		if (direction === 'down') {
			$(".now_ul").addClass("strength_li");
			$(".now_ul").animate({ opacity: '1'}, { duration: 300, easing: 'linear' });
			$(".now_ul li:nth-child(1)").addClass("current");
			$(".now_ul li").not("li:nth-child(1)").removeClass("current");
		}else{
			$(".now_ul").animate({ opacity: '0'}, { duration: 300, easing: 'linear' });
			$(".now_ul li:nth-child(1)").addClass("current");
			$(".now_ul li").not("li:nth-child(1)").removeClass("current");
		}
	},{offset:iOffset});

	$(".vision").waypoint(function(direction){
		if (direction === 'down') {
			$(".now_ul").removeClass("strength_li");
			$(".now_ul").addClass("vision_li");
			$(".now_ul li:nth-child(2)").addClass("current");
			$(".now_ul li").not("li:nth-child(2)").removeClass("current");
		}else{
			$(".now_ul").removeClass("vision_li");
			$(".now_ul").addClass("strength_li");
			$(".now_ul li:nth-child(1)").addClass("current");
			$(".now_ul li").not("li:nth-child(1)").removeClass("current");
		}
	},{offset:iOffset});

	$(".recruit").waypoint(function(direction){
		if (direction === 'down') {
			$(".now_ul").removeClass("vision_li");
			$(".now_ul").addClass("recruit_li");
			$(".now_ul li:nth-child(3)").addClass("current");
			$(".now_ul li").not("li:nth-child(3)").removeClass("current");
		}else{
			$(".now_ul").removeClass("recruit_li");
			$(".now_ul").addClass("vision_li");
			$(".now_ul li:nth-child(2)").addClass("current");
			$(".now_ul li").not("li:nth-child(2)").removeClass("current");
		}
	},{offset:iOffset});
},
/**
 * リサイズイベントセット
 */
setResize:function(){
	$(window).bind("resize",function(e){_manager.resize(e,true);});
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
setParallax:function(){
  var target1 = $(".vision").find(".inner_img");
  var targetPosOT1 = target1.offset().top;
  var target2 = $(".recruit").find(".inner_img");
  var targetPosOT2 = target2.offset().top;
  var targetFactor = 0.25;
  var windowH = $(window).height();
  var scrollYStart1 = targetPosOT1 - windowH;
  var scrollYStart2 = targetPosOT2 - windowH;
  $(window).on('scroll',function(){
    var scrollY = $(this).scrollTop();
    if(scrollY > scrollYStart1){
      target1.css('transform', 'translateY(' + (scrollY - targetPosOT1) * targetFactor*0.18 + 'px)');
    }else{
      target1.css('transform','translateY(0px)');
    }
    if(scrollY > scrollYStart2){
      target2.css('transform', 'translateY(' + (scrollY - targetPosOT2) * targetFactor*0.18 + 'px)');
    }else{
      target2.css('transform','translateY(0px)');
    }
  });
},
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
/**
 * resize
 */
resize:function(e,b){
	var $window = $(window);
	var iWindowW = window.innerWidth;
	var iWindowH = window.innerHeight;
	// $(".fv").css("height",iWindowH);
}
});
//definition end